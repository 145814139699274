import { SigningStargateClient } from "@cosmjs/stargate";
import { Decimal } from "@cosmjs/math";
import { TopupInfo } from "../models/topupInfo";
import { SecretNetworkClient } from "secretjs";

const sendTransaction = async (
  chainId: string,
  address: string,
  topupInfo: TopupInfo,
  amount: string
) => {
  if (window.keplr) {
    const offlineSigner = window.keplr.getOfflineSignerOnlyAmino(chainId);
    const amountStr = String(
      Math.round(Number(amount) * 10 ** topupInfo.decimals)
    );
    const encryptionUtils = window.keplr.getEnigmaUtils(chainId);
    const client = new SecretNetworkClient({
      url: process.env.NEXT_PUBLIC_SECRET_LCD_URL || "",
      chainId: chainId,
      wallet: offlineSigner,
      walletAddress: address,
      encryptionUtils,
    });
    if (["native", "ibc"].includes(topupInfo.type)) {
      const tx = await client.tx.bank.send(
        {
          from_address: address,
          to_address: topupInfo.topupAddress,
          amount: [
            {
              amount: amountStr,
              denom: topupInfo.denom,
            },
          ],
        },
        {
          gasLimit: 40000,
          gasPriceInFeeDenom: 0.25,
        }
      );
      return tx.transactionHash;
    } else if (topupInfo.type === "snip20") {
      const params = {
        sender: address,
        contract_address: topupInfo.denom,
        msg: {
          transfer: {
            amount: amountStr,
            recipient: topupInfo.topupAddress,
          },
        },
      };
      const tx = await client.tx.snip20.transfer(params, {
        gasLimit: 80000,
        gasPriceInFeeDenom: 0.25,
      });
      return tx.transactionHash;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export default sendTransaction;
