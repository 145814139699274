import { PaletteMode, ThemeOptions } from "@mui/material";

const getTheme: (mode: PaletteMode) => ThemeOptions = (mode) => ({
  palette:
    mode === "light"
      ? {
          mode,
          primary: {
            dark: "#0E1E59",
            main: "#32427E",
            light: "#2FC3FF",
          },
          background: {
            default: "#fff",
            paper: "#F2F3F8",
          },
          text: {
            secondary: "#687297",
          },
        }
      : {
          mode,
          primary: {
            dark: "#fff",
            main: "#fff",
            light: "#2FC3FF",
          },
          background: {
            default: "#0E1E59",
            paper: "#32427E",
          },
          text: {
            secondary: "#98A0BD",
          },
        },
  shape: {
    borderRadius: 8,
  },
  typography: (palette) => ({
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontWeight: 700,
      fontFamily: "Poppins, sans-serif",
      color: palette.primary.dark,
      fontSize: "5.5rem",
    },
    h2: {
      fontWeight: 700,
      fontFamily: "Poppins, sans-serif",
      color: palette.primary.dark,
    },
    h3: {
      fontWeight: 700,
      fontFamily: "Poppins, sans-serif",
      color: palette.primary.dark,
    },
    h4: {
      fontWeight: 700,
      fontFamily: "Poppins, sans-serif",
      color: palette.primary.dark,
    },
    h5: {
      fontWeight: 700,
      fontFamily: "Poppins, sans-serif",
      color: palette.primary.dark,
    },
    h6: {
      fontWeight: 700,
      fontFamily: "Poppins, sans-serif",
      fontSize: "1.15rem",
      color: palette.primary.dark,
    },
    body2: {
      fontSize: "1rem",
      color: palette.text.secondary,
    },
  }),
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.gradient": {
            background: `linear-gradient(103.63deg, #002BC9 -3.81%, #F100F5 94.98%)`,
            backgroundClip: `text`,
            textFillColor: `transparent`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: ({ theme: { spacing } }) => ({
          textTransform: "none",
          borderRadius: 20,
          padding: spacing(0.5, 3),
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ theme: { palette } }) => ({
          height: 8,
          borderRadius: 4,
          backgroundColor: palette.background.paper,
        }),
        barColorPrimary: ({ theme: { palette } }) => ({
          backgroundColor: palette.primary.light,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme: { palette } }) => ({
          backgroundColor: palette.background.default,
          backgroundImage: "none",
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme: { shape } }) => ({
          "& .MuiButton-root": {
            borderRadius: shape.borderRadius,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme: { palette, spacing } }) => ({
          backgroundColor: palette.background.paper,
          borderRadius: 40,
          border: "none",
          "& .MuiInputBase-root": {
            padding: spacing(0, 4) + " !important",
          },
          "& input": {
            padding: spacing(2, 0) + " !important",
          },
          "& .MuiInputAdornment-positionStart": {
            marginLeft: spacing(-2),
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.background.paper,
            borderRadius: 40,
          },
        }),
      },
    },
  },
});
export default getTheme;
