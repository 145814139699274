import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";

export const colorModeState = atom<"light" | "dark">({
  key: "colorMode",
  default: "light",
  effects: [
    ({ onSet }) => {
      onSet(async (colorMode) => {
        localStorage.setItem("colorMode", colorMode);
      });
    },
  ],
});

export const useInitializeSettings = () => {
  const setColorMode = useSetRecoilState(colorModeState);

  useEffect(() => {
    const persistedColorMode = localStorage.getItem("colorMode");
    persistedColorMode && setColorMode(persistedColorMode as any);
  }, [setColorMode]);
};
