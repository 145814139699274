import { get } from "lodash";
import { fromBase64, toBase64 } from "secretjs";

const maxRetry = 10;

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const refetchTx = async (txHash: string) => {
  let retried = 0;
  let txResult;
  while (retried < maxRetry && !txResult) {
    await delay(5000);
    txResult = await fetch(
      `${process.env.NEXT_PUBLIC_SECRET_LCD_URL}/cosmos/tx/v1beta1/txs/${txHash}`
    )
      .then((r) => r.json())
      .catch(() => undefined);
  }
  return txResult;
};

const getTxEncryptionKey = async (txHash: string, chainId: string) => {
  if (window.keplr) {
    const encryptionUtils = window.keplr.getEnigmaUtils(chainId);
    const txResult = await refetchTx(txHash);
    const msg = get(txResult, "tx.body.messages[0].msg", "");
    if (!msg) {
      return "";
    }
    const nonce = fromBase64(msg).slice(0, 32);
    const encryptionKey = toBase64(
      await encryptionUtils.getTxEncryptionKey(nonce)
    );
    return encryptionKey;
  }
  return "";
};

export default getTxEncryptionKey;
