import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { useAccount } from "./account";
import { TopupInfo } from "./topupInfo";
import getTxEncryptionKey from "../utils/getTxEncryptionKey";
import sendTransaction from "../utils/sendTransaction";

export interface Transaction {
  id: string;
  date: number;
  amount: number;
  description: string;
  hash: string;
  fx: {
    amount: number;
    currency: string;
  };
  merchant: {
    category: string;
    name: string;
    longName: string;
    city: string;
    country: string;
    icon: string;
    url: string;
  };
  status: string;
  fee: {
    amount: number;
    currency: string;
    description: string;
  }[];
}

export interface Limit {
  daily: {
    limit: number;
    used: number;
  };
  monthly: {
    limit: number;
    used: number;
  };
}

export interface Card {
  id: string;
  notRegistered: boolean;
  tier: {
    name: string;
    spendingLimit: number;
    spendingRewards: number;
    stakingEurRequirements: number;
  };
  limits: {
    posPurchase: Limit;
    internet: Limit;
    contactless: Limit;
    withdrawal: Limit;
  };
  maskedCardNumber: string;
  expiryDate: number;
  status: string;
  currency: string;
  cardHolder: string;
  balance: number;
  mobile: string;
  rewards: number;
  transactions: Transaction[];
}

const emptyLimit: Limit = {
  daily: { limit: 0, used: 0 },
  monthly: { limit: 0, used: 0 },
};
const emptyCard: Card = {
  id: "",
  notRegistered: false,
  tier: {
    name: "",
    spendingLimit: 0,
    spendingRewards: 0,
    stakingEurRequirements: 0,
  },
  limits: {
    posPurchase: emptyLimit,
    internet: emptyLimit,
    contactless: emptyLimit,
    withdrawal: emptyLimit,
  },
  maskedCardNumber: "",
  expiryDate: 0,
  status: "",
  currency: "",
  cardHolder: "",
  balance: 0,
  mobile: "",
  rewards: 0,
  transactions: [],
};

export const cardState = atom<Card>({
  key: "card",
  default: emptyCard,
});

export const useCard = () => {
  const { account } = useAccount();
  const [card, setCard] = useRecoilState(cardState);

  const fetchCard = useCallback(async () => {
    try {
      if (account.address) {
        const result = await fetch(
          `${process.env.NEXT_PUBLIC_CARD_API_URL}/getAccount`,
          {
            method: "POST",
            body: JSON.stringify(account),
          }
        ).then((r) => r.json());
        if (result.success === false) {
          throw new Error(result.message);
        }
        if (result.maskedCardNumber) {
          setCard(result);
        }
      } else if (!account.address) {
        setCard(emptyCard);
      }
    } catch (err: any) {
      console.log(err.message);
      if (err.message === "account not found") {
        setCard({ ...emptyCard, notRegistered: true });
      }
    }
  }, [account, setCard]);

  const topup = useCallback(
    async (
      topupInfo: TopupInfo,
      amount: string,
      topupAsset: string,
      currency: string,
      defaultTxHash?: string
    ) => {
      let txHash = defaultTxHash || "";
      let encryptionKey = "";
      if (!txHash) {
        txHash = await sendTransaction(
          account.chainId,
          account.address,
          topupInfo,
          amount
        );
      }
      encryptionKey = await getTxEncryptionKey(txHash, account.chainId);
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_CARD_API_URL}/topup`,
        {
          method: "POST",
          body: JSON.stringify({
            ...account,
            topupAsset,
            currency,
            txHash,
            encryptionKey,
          }),
        }
      ).then((r) => r.json());
      if (result.success === false) {
        throw new Error(result.message);
      }
      return result;
    },
    [account]
  );

  const createCard = useCallback(
    async (
      name: string,
      mobile: string,
      password: string,
      viewingKey: string,
      tier: string
    ) => {
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_CARD_API_URL}/createCard`,
        {
          method: "POST",
          body: JSON.stringify({
            ...account,
            name,
            mobile,
            password,
            viewingKey,
            tier,
            referer: "",
          }),
        }
      ).then((r) => r.json());
      if (result.success === false) {
        throw new Error(result.message);
      }
      return result;
    },
    [account]
  );

  const change3DS = useCallback(
    async (params: { mobile?: string; password?: string }) => {
      if (!window.keplr) {
        throw new Error("keplr not installed");
      }
      const timestamp = new Date().toISOString();
      const signed = await window.keplr.signArbitrary(
        account.chainId,
        account.address,
        `Fina Card: Change 3DS Settings at ${timestamp}`
      );
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_CARD_API_URL}/change3DS`,
        {
          method: "POST",
          body: JSON.stringify({
            ...account,
            signature: signed.signature,
            timestamp,
            ...params,
          }),
        }
      ).then((r) => r.json());
      if (result.success === false) {
        throw new Error(result.message);
      }
      return result;
    },
    [account]
  );

  const toggleBlockCard = useCallback(async () => {
    if (!window.keplr) {
      throw new Error("keplr not installed");
    }
    const timestamp = new Date().toISOString();
    const signed = await window.keplr.signArbitrary(
      account.chainId,
      account.address,
      `Fina Card: Toggle Block Card at ${timestamp}`
    );
    const result = await fetch(
      `${process.env.NEXT_PUBLIC_CARD_API_URL}/toggleBlockCard`,
      {
        method: "POST",
        body: JSON.stringify({
          ...account,
          signature: signed.signature,
          timestamp,
        }),
      }
    ).then((r) => r.json());
    if (result.success === false) {
      throw new Error(result.message);
    }
    return result;
  }, [account]);

  const claimRewards = useCallback(async () => {
    if (!window.keplr) {
      throw new Error("keplr not installed");
    }
    const result = await fetch(
      `${process.env.NEXT_PUBLIC_CARD_API_URL}/claimRewards`,
      {
        method: "POST",
        body: JSON.stringify(account),
      }
    ).then((r) => r.json());
    if (result.success === false) {
      throw new Error(result.message);
    }
    return result;
  }, [account]);

  const changeTier = useCallback(
    async (viewingKey: string, tier: string) => {
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_CARD_API_URL}/changeTier`,
        {
          method: "POST",
          body: JSON.stringify({
            ...account,
            viewingKey,
            tier,
          }),
        }
      ).then((r) => r.json());
      if (result.success === false) {
        throw new Error(result.message);
      }
      return result;
    },
    [account]
  );

  return {
    card,
    topup,
    createCard,
    fetchCard,
    change3DS,
    toggleBlockCard,
    claimRewards,
    changeTier,
  };
};
