import { useCallback, useEffect, useMemo } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import NodeRSA from "node-rsa";
import { useCard } from "./card";
import { SecretNetworkClient } from "secretjs";

const chainId = process.env.NEXT_PUBLIC_DEFAULT_CHAIN_ID || "";

export interface Account {
  address: string;
  pubKey: string;
  signature: string;
  chainId: string;
}

const emptyAccount: Account = {
  address: "",
  pubKey: "",
  signature: "",
  chainId: "",
};

export const accountState = atom<Account>({
  key: "account",
  default: emptyAccount,
  effects: [
    ({ onSet }) => {
      onSet(async (account) => {
        localStorage.setItem("account", JSON.stringify(account));
      });
    },
  ],
});

export const useAccount = () => {
  const [account, setAccount] = useRecoilState(accountState);

  const connect = useCallback(async () => {
    if (window.keplr) {
      await window.keplr.enable(chainId);
      const account = await window.keplr.getKey(chainId);
      const signed = await window.keplr.signArbitrary(
        chainId,
        account.bech32Address,
        "Fina Card: Sign In"
      );
      const newAccount = {
        address: account.bech32Address,
        pubKey: signed.pub_key.value,
        signature: signed.signature,
        chainId,
      };
      setAccount(newAccount);
    }
  }, [setAccount]);

  const disconnect = useCallback(() => {
    setAccount(emptyAccount);
    localStorage.clear();
  }, [setAccount]);

  return { account, connect, disconnect };
};

export const useInitializeAccount = () => {
  const setAccount = useSetRecoilState(accountState);
  const { fetchCard } = useCard();

  const initializeAccount = useCallback(async () => {
    const persistedAccountString = localStorage.getItem("account");
    const persistedAccount = persistedAccountString
      ? JSON.parse(persistedAccountString)
      : undefined;
    if (window.keplr && persistedAccount) {
      const account = await window.keplr.getKey(chainId);
      if (account.bech32Address === persistedAccount.address) {
        setAccount(persistedAccount);
      }
    }
  }, [setAccount]);

  useEffect(() => {
    setTimeout(() => initializeAccount(), 300);
  }, [initializeAccount]);

  useEffect(() => {
    fetchCard();
  }, [fetchCard]);
};

export const useSecretNetworkClient = () => {
  const { account } = useAccount();
  const secretNetworkClient = useMemo(() => {
    if (account.address) {
      const offlineSigner = window.keplr?.getOfflineSignerOnlyAmino(
        account.chainId
      );
      const enigmaUtils = window.keplr?.getEnigmaUtils(account.chainId);
      const secretjs = new SecretNetworkClient({
        url: process.env.NEXT_PUBLIC_SECRET_LCD_URL || "",
        chainId: account.chainId,
        walletAddress: account.address,
        wallet: offlineSigner,
        encryptionUtils: enigmaUtils,
      });
      return secretjs;
    }
    return null;
  }, [account.address, account.chainId]);
  return secretNetworkClient;
};
