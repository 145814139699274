import "@fontsource/poppins/700.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "react-virtualized/styles.css";
import "../assets/css/global.css";
import { createTheme, ThemeProvider } from "@mui/material";
import type { AppProps } from "next/app";
import { RecoilRoot, useRecoilValue } from "recoil";
import { FC } from "react";
import { useInitializeAccount } from "../models/account";
import getTheme from "../utils/theme";
import Head from "next/head";
import { colorModeState, useInitializeSettings } from "../models/misc";
import { NextSeo } from "next-seo";

const title = "Fina Card Portal";
const description = "Web3 Visa Prepaid Card Connected to Any Wallet";
const themeColor = "#0E1E59";

const Main: FC<any> = ({ component }) => {
  useInitializeAccount();
  useInitializeSettings();
  const colorMode = useRecoilValue(colorModeState);

  return (
    <ThemeProvider theme={createTheme(getTheme(colorMode))}>
      {component}
    </ThemeProvider>
  );
};

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <NextSeo
        title={title}
        description={description}
        themeColor={themeColor}
        openGraph={{
          url: "https://card.fina.cash",
          title,
          description,
          images: [
            {
              url: "https://card.fina.cash/ogimage.png",
              width: 1200,
              height: 630,
            },
          ],
          site_name: "Fina",
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
      />
      <RecoilRoot>
        <Main component={<Component {...pageProps} />} />
      </RecoilRoot>
    </>
  );
}
